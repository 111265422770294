import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import '../components/all.sass'

//queries for the productDHFs and displays product names
function IndexPage({ pageContext }) {
  let { products } = pageContext
  return (
    <Layout title="Product Listing">
      <div className="columns">
        <div className="column is-one-quarter">
          {products.map((pname) => (
            <div key={pname}>
              <Link
                to={`/${pname.toString()}`}
                className="button is-medium is-info is-inverted is-fullwidth"
              >
                {pname}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
